import React, { useState } from 'react';
import './Home.css';
import Navbar from '../Navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import Footer from '../Footer/Footer'

function Home() {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');

  const submitNewsletter = async (e) => {
    e.preventDefault();

    if(!email) {
      alert("Please enter all the fields !");
      return;
    }

    try {
      const db = getFirestore();
      const contactUsCollectionRef = collection(db, 'newsletter');
      const contactUsDocRef = doc(contactUsCollectionRef);
  
      await setDoc(contactUsDocRef, {
        email,
      });
      setEmail("");
      document.querySelector('.successmessage').style.display = 'block';
      document.querySelector('.successmessage').style.textAlign = 'center';
      } catch (err) {
        alert(err.message);
      }
  }
  return (
    <>
    <Navbar/>
    <div className='home__wrapper'>
    <div className='home'>
      <div className='hero'>
      <h1 className='highlight'><span class="removemedia">🎵</span> Where Every Note Sparks Possibilities <span class="removemedia">🎵</span></h1>
      <h3>Elevating Special Students through the Transformative Rhythm of Music</h3>
      <p>Welcome to our non-profit, spreading musical joy to special students. We collaborate with special schools and NGOs, making music accessible for a harmonious and uplifting experience.</p>
      <button className='button' onClick={(e) => {
        e.preventDefault();
        navigate("/contactus")
      }}>Contact Us</button>
      </div>

      <div className='collab_schools'>
        <h1>Trusted By</h1>
        {/* <img src={require('../../assets/schoolofautism.png')} /> */}
      </div>

<div className="uniqueniche">
      <div className='uniqueniche_text'>
        <h1>Our Unique Method</h1>
        <p>At Neurhythm, we stand out as a research-driven force, specializing in the translation of academic insights into impactful real-world applications. In collaboration with numerous special schools, our focus is on the execution of meticulously compiled literature and research papers in live educational settings. Through this distinctive approach, we actively contribute to the advancement of music therapy methodologies, particularly tailored for children facing cognitive challenges like autism and ADHD.</p>
        <p>What sets Neurhythm apart is our commitment to bridging the gap between research and practical implementation seamlessly. Our methodologies unfold in real-time within school environments, creating tailored solutions that resonate with individual learning needs.</p>
      </div>
      <div className='uniqueniche_image'>
        <img src={require('../../assets/neurhythm_image1.png')} />
      </div>
    </div>

    <div className='newsletter'>
      <h1>Subscribe to our newsletter</h1>
      <p>Subscribe to our newsletter and stay harmoniously connected, gaining exclusive insights into our transformative musical interventions, innovative research, and impactful stories of progress in the world of Neurhythm.</p>
      <form className='newsletter_form'>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email"/>
        <button onClick={(e) => submitNewsletter(e)}><img src={require('../../assets/white_arrow.png')} /></button>
      </form>
      <p className='successmessage'>Successfully registered to newsletter</p>
    </div>

    <Footer />
    </div>
    </div>
    </>
  )
}

export default Home