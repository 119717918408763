import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBVBiv3OXZgSaTstVwaIx7k2BeyfxhyE_g",
    authDomain: "neurhythm-df6e5.firebaseapp.com",
    projectId: "neurhythm-df6e5",
    storageBucket: "neurhythm-df6e5.appspot.com",
    messagingSenderId: "519966501590",
    appId: "1:519966501590:web:8378ef3b02b3bbc6ca7777",
    measurementId: "G-DMGHBFY2BH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app);
const db = getFirestore(app)

export {db, auth};