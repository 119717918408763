import React, { useState } from 'react'
import './Login.css';
import { auth } from '../../firebase';
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

    const loginUser = async (e) => {
      e.preventDefault();
      setLoading(true);
        if(!email || !password) {
            alert("Please enter all the fields !");
            setLoading(false);
            return;
        }

        try {
          const authUser = await signInWithEmailAndPassword(auth, email, password);    
            navigate("/");
            setLoading(false);
        } catch (err) {
          setLoading(false);
          if (
            err.message === "The password is invalid or the user does not have a password."
          ) {
            alert("Please check your credentials again");
          } else if (
            err.message ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            navigate("/register");
          } else {
            alert(err.message);
          }
        }
    }

  return (
    <div className='login'>
        <div className='login__form'>
            <h1>Login to Neurhythm</h1>
            <hr />
            <div className='epair'>
            <label>Email</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading}/>
            </div>
            <div className='epair'>
            <label>Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading}/>
            </div>
            {
              !loading ? (
                <button onClick={(e) => loginUser(e)}>Login</button>
              ) : (
                <button><i class="fa fa-spinner fa-spin"></i> Loading</button>
              )
            }
            <p>Don't have an account ? <Link to="/signup"><label>Sign Up</label></Link></p>
        </div>
    </div>
  )
}

export default Login