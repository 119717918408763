import React, { useState } from 'react'
import './ContactUs.css';
import Navbar from '../Navbar/Navbar';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function ContactUs() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [name,setName] = useState('');
  const [message, setMessage] = useState('');
  let navigate = useNavigate();

  const sendForm = async (e) => {
    e.preventDefault();

    setLoading(true);

    if(!message || !email || !name) {
      alert("Please enter all the fields !");
      setLoading(false);
      return;
    }

    try {
    const db = getFirestore();
    const contactUsCollectionRef = collection(db, 'contactUs');
    const contactUsDocRef = doc(contactUsCollectionRef);

    await setDoc(contactUsDocRef, {
      fullName: name,
      email,
      message,
    });
    setLoading(false);
    setEmail("");
    setMessage("");
    setName("");
    alert("Successfully submitted message !");
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  }

  return (
   <>
    <Navbar />
    <div className='contactForm__wrapper'>
    <div className='contactForm'>
      <div className='contactIllustration_pane'>
        {/* <img src={require('../../assets/illustrations/ill1.svg')} /> */}
      </div>
      <div className='contactform_pane'>
      <h1>Contact Us</h1>
      <hr />
      <div className='epair_2'>
            <label>Full Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} disabled={loading}/>
            </div>
            <div className='epair_2'>
            <label>Email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" disabled={loading}/>
            </div>

            <div className='epair_2'>
            <label>Message</label>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} rows="4" disabled={loading}/>
            </div>

            {
              !loading ? (
                <button onClick={(e) => sendForm(e)}>Submit</button>
              ) : (
                <button><i class="fa fa-spinner fa-spin"></i> Loading</button>
              )
            }

      </div>
    </div>
    </div>
   </>
  )
}

export default ContactUs