import React, { useEffect, useState } from 'react'
import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../firebase';

function Navbar() {

  const [user, setUser] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let x = document.getElementById("changingNav");
      let l1 = document.getElementById("link1");
      let l2 = document.getElementById("link2");
      let l3 = document.getElementById("link3");
      let l4 = document.getElementById("link4");
      if (window.scrollY > 200) {
        x.style.backgroundColor = "black";
        x.style.boxShadow = "0 2px 15px rgba(0, 0, 0, 0.5)";
        l1.style.color = "white";
        l2.style.color = "white";
        l3.style.color = "white";
        l4.style.color = "white";
      } else {
        x.style.backgroundColor = "transparent";
        x.style.boxShadow = "none";
        l1.style.color = "black";
        l2.style.color = "black";
        l3.style.color = "black";
        l4.style.color = "black";
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      alert(error.message);
      navigate("/");
    }
  }

  return (
    <>
<div id="overlayNav" class="overlay">
  <a href="javascript:void(0)" className="closebtn" onClick={(e) => {
    e.preventDefault();
    document.getElementById("overlayNav").style.width = "0%";
  }}>&times;</a>
  <div class="overlay-content">
    <a onClick={() => navigate("/")}>Home</a>
    <a onClick={() => navigate("/about")}>Abouts</a>
    <a onClick={() => navigate("/research")}>Research</a>
    <a onClick={() => navigate("/contactus")}>Contact Us</a>
    {
      user ? (
        <a onClick={() => logout()}>Logout</a>
      ) : (
        <a onClick={() => navigate("/login")}>Login</a>
      )
    }
  </div>
</div>
    <div className='navbar'>
      <div className='navbar__left'>
        <Link to="/"><img src={require('../../assets/neurhythm2_trans.png')} /></Link>
      </div>
      <div className='navbar__middle' id="changingNav">
      <Link to="/"><p id="link1">Home</p></Link>
        <Link to="/about"><p id="link2">About</p></Link>
        <Link to="/research"><p id="link3">Research</p></Link>
        <Link to="/contactus"><p id="link4">Contact Us</p></Link>
      </div>
      <div className='navbar__right'>
          {
            user ? (
              (
                <>
                <Link to="/"><p>{user.displayName}</p></Link>
                <button className='blackbutton' onClick={() => logout()}>Logout</button>
                </>
              )
            ) : (
              <Link to="/login"><p>Login</p></Link>
            )
          }
      </div>
      <div className='navbar__right_toggle'>
        <button onClick={(e) => {
          e.preventDefault();
          document.getElementById("overlayNav").style.width = "100%";
        }}><span>&#9776;</span></button>
      </div>
    </div>
    </>
  )
}

export default Navbar