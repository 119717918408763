import React, { useState } from 'react'
import { auth, db } from '../../firebase';
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

    const registerUser = async (e) => {
      setLoading(true);
      e.preventDefault();
        if(!email || !password || !name) {
            alert("Please enter all the fields !");
            return;
        }

        try {
          const auth = getAuth();
          const authUser = await createUserWithEmailAndPassword(auth, email, password);
          const user = auth.currentUser;
        
          if (user) {
            await updateProfile(user, {
              displayName: name,
              photoURL: "https://i.ibb.co/1zmBtwr/84241059-189132118950875-4138507100605120512-n.jpg"
            });
        
            const db = getFirestore();
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, {
              uid: user.uid,
              displayName: user.displayName,
              email: user.email,
              admin: 0,
              photoURL: "https://i.ibb.co/1zmBtwr/84241059-189132118950875-4138507100605120512-n.jpg",
              organisation: 0,
              role: "",
            });
            setLoading(false);
            navigate("/");
          }
        } catch (err) {
          setLoading(false);
          if (
            err.message === "The password is invalid or the user does not have a password."
          ) {
            alert("Please check your credentials again");
          } else if (
            err.message ===
            "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            navigate("/register");
          } else {
            alert(err.message);
          }
        }
        
    }

  return (
    <div className='login'>
        <div className='login__form'>
            <h1>Create a Neurhythm account</h1>
            <hr />
            <div className='epair'>
            <label>Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} disabled={loading}/>
            </div>
            <div className='epair'>
            <label>Email</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={loading}/>
            </div>
            <div className='epair'>
            <label>Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} disabled={loading}/>
            </div>
            {
              !loading ? (
                <button onClick={(e) => registerUser(e)}>Sign Up</button>
              ) : (
                <button><i class="fa fa-spinner fa-spin"></i> Loading</button>
              )
            }
            <p>Already have an account ? <Link to="/login"><label>Log in</label></Link></p>
            <hr />
            <p>By signing up, you agree to the <label>Terms of Service</label> and <label>Data Processing Agreement</label>.</p>
        </div>
    </div>
  )
}

export default Register