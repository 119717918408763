import React from 'react'
import Navbar from '../Navbar/Navbar'
import './About.css';

function About() {
  return (
    <div className='about'>
      <Navbar />
        <div className='whiteBanner'>
          <h1>About</h1>
        </div>

        <div className='teamCards'>
        <div className='teamCard'>
          <img src="" className='teamCard__avatar' />
          <div className='teamCard__details'>
            <h1>Rutwik K. Routu</h1>
            <p>Founder</p>
            <p>Description</p>
          </div>
          </div>
        </div>

    </div>
  )
}

export default About