import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>
      <div class="footer-row">
        <img src={require('../../assets/neurhythm2_trans.png')} alt="Neurhythm Logo" />
        <Link to="/"><p>Home</p></Link>
        <Link to="/gallery"><p>Gallery</p></Link>
        <Link to="/schools"><p>Schools</p></Link>
        <Link to="/contactus"><p>Contact Us</p></Link>
      </div>

    <div class="copyright-line">
      &copy; 2024 Neurhythm. All rights reserved.
    </div>
  </footer>
  )
}

export default Footer